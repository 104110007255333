import React from "react";
import { graphql } from "gatsby";
import ShopTemplate from "../templates/shop";

const environment = {
  lang: "nl",
  path: "/shop",
  altHrefLang: [
    {
      lang: "nl",
      path: "/shop",
    },
    {
      lang: "en",
      path: "/en/shop",
    },
  ],

  theme: "blue",
};

const content = {
  title:
    "Improve your Dutch offline, with detailed explanations, exercises, tricks and solutions. In Dutch and English.",
  subtitle:
    "All books have been written and curated by Our Professional teachers, based on experience.",
};

const ShopNl = (props) => {
  return (
    <ShopTemplate {...props} environment={environment} content={content} />
  );
};

export default ShopNl;

export const query = graphql`
  query BookItemsQueryNl {
    books: allContentfulBook(
      filter: { node_locale: { eq: "nl" } }
      sort: { fields: [createdAt], order: [DESC] }
    ) {
      edges {
        node {
          title
          slug
          price
          productId
          subtitle
          level
          fileIdSnipcart
          contentful_id
          numberOfPages
          node_locale
          studentLevel {
            level
          }
          image {
            fixed: gatsbyImageData(width: 150)
            main: gatsbyImageData(width: 200, layout: CONSTRAINED)
          }
          __typename
        }
      }
    }
  }
`;
